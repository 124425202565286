@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");


body {
  background: #f0f2f5;
  font-size: 18px;
}

.ant-modal-title {
  text-align: center;
  font-size: 1.2rem;
}

.field {
  margin-bottom: 1rem;
  position: relative;

  input, textarea {
    display: block;
    width: 100%;
    height: 3rem;
    padding: 0 1em;
    color: #404040;
    outline: 0;
    font-size: 1rem;
    border: 2px solid #bfbfbf;
  }

  .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-top: .75rem;
  }

  .ant-select-selection {
    height: 3rem;
    font-size: 1rem;
  }

  .ant-select-selection__rendered {
    margin-top: .5rem;
  }

  input::placeholder, textarea::placeholder {
    color: #a4abb3;
  }

  textarea {
    padding: .75rem;
  }

  label {
    margin-bottom: 0.5rem;
    font-weight: bolder;
  }

  input {
    + label {
      position: absolute;
      top: -1.45rem;
      left: 0.5rem;
      transition: all 0.1s;
      opacity: 1;
      z-index: 1;
      background: white;
      padding: 0 5px;
      transform: translateY(60%);
    }

    &:placeholder-shown + label {
      opacity: 0;
      transform: translateY(50%);
    }
  }

  textarea {
    + label {
      position: absolute;
      top: -1.6rem;
      left: 0.5rem;
      transition: all 0.1s;
      opacity: 1;
      z-index: 1;
      background: white;
      padding: 0 5px;
      transform: translateY(60%);
    }
  }

  .ant-calendar-picker, .ant-select {
    width: 100%;
  }

  .ant-calendar-picker + label, .ant-select + label {
    position: absolute;
    top: -1.45rem;
    left: 0.5rem;
    transition: all 0.1s;
    opacity: 1;
    z-index: 1;
    background: white;
    padding: 0 5px;
    transform: translateY(60%);
  }

  .error {
    margin-top: 0.375rem;
    color: #dc0000;
    text-align: left;
    font-size: 1rem;

    span {
      margin-left: 0.25rem;
    }
  }
}

.submit {
  display: flex;
  justify-content: flex-end;
  margin-right: 0.5em;

  button {
    margin-left: 0.5rem;
  }
}

/*Colors*/

.red {
  color: #dc4b39 !important;
}

.blue {
  color: #4785eb !important;
}

.green {
  color: #3cb454 !important;
}

.yellow {
  color: #fabc08 !important;
}

.mark {
  background-color: #fcf8e3;
}

.ml-1 {
  margin-left: .5rem;
}

.ant-page-header {
  margin: 1rem .5rem 0;
}
