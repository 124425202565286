@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);
.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.questions {
  background-color: white;
  padding: 1rem;
  border-radius: .5rem;
  margin: 5%; }
  .questions .feedback-steps {
    margin: 1rem; }
  .questions .individual-plan {
    margin: 1rem 0;
    text-align: center; }
    .questions .individual-plan .submit {
      margin: 1rem 0;
      justify-content: center; }
  .questions .steps-action, .questions .steps-content {
    margin: 1rem 0; }
  .questions .error {
    text-align: center !important; }
  .questions p {
    margin-top: 0;
    font-size: 1rem; }
  .questions h3 {
    font-size: 1rem;
    text-indent: 1.5rem;
    text-align: justify;
    font-weight: normal; }
  .questions .text-center {
    text-align: center; }
  .questions .ant-radio-wrapper {
    font-size: 1.15rem;
    font-weight: normal; }
  .questions .section {
    border: 2px solid #bfbfbf;
    margin: 1.3em 0;
    padding: 1rem;
    font-size: 1rem; }
  .questions .section-title {
    font-weight: bolder; }

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  -webkit-transition: color .3s;
  transition: color .3s; }
  .trigger:hover {
    color: #1890ff; }

.logo {
  height: 32px;
  margin: 16px; }

.header {
  float: right;
  margin-right: 2rem;
  margin-top: 1rem; }
  .header:hover {
    cursor: pointer; }
  .header img {
    border-radius: 50%;
    width: 32px;
    height: 32px; }
  .header span {
    margin-left: 0.25rem;
    font-size: 1rem;
    color: black; }

@media (max-width: 800px) {
  .header {
    margin-bottom: 1rem; } }

body {
  background: #f0f2f5;
  font-size: 18px; }

.ant-modal-title {
  text-align: center;
  font-size: 1.2rem; }

.field {
  margin-bottom: 1rem;
  position: relative; }
  .field input, .field textarea {
    display: block;
    width: 100%;
    height: 3rem;
    padding: 0 1em;
    color: #404040;
    outline: 0;
    font-size: 1rem;
    border: 2px solid #bfbfbf; }
  .field .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-top: .75rem; }
  .field .ant-select-selection {
    height: 3rem;
    font-size: 1rem; }
  .field .ant-select-selection__rendered {
    margin-top: .5rem; }
  .field input::-webkit-input-placeholder, .field textarea::-webkit-input-placeholder {
    color: #a4abb3; }
  .field input::-moz-placeholder, .field textarea::-moz-placeholder {
    color: #a4abb3; }
  .field input:-ms-input-placeholder, .field textarea:-ms-input-placeholder {
    color: #a4abb3; }
  .field input::-ms-input-placeholder, .field textarea::-ms-input-placeholder {
    color: #a4abb3; }
  .field input::placeholder, .field textarea::placeholder {
    color: #a4abb3; }
  .field textarea {
    padding: .75rem; }
  .field label {
    margin-bottom: 0.5rem;
    font-weight: bolder; }
  .field input + label {
    position: absolute;
    top: -1.45rem;
    left: 0.5rem;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    opacity: 1;
    z-index: 1;
    background: white;
    padding: 0 5px;
    -webkit-transform: translateY(60%);
            transform: translateY(60%); }
  .field input:placeholder-shown + label {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%); }
  .field textarea + label {
    position: absolute;
    top: -1.6rem;
    left: 0.5rem;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    opacity: 1;
    z-index: 1;
    background: white;
    padding: 0 5px;
    -webkit-transform: translateY(60%);
            transform: translateY(60%); }
  .field .ant-calendar-picker, .field .ant-select {
    width: 100%; }
  .field .ant-calendar-picker + label, .field .ant-select + label {
    position: absolute;
    top: -1.45rem;
    left: 0.5rem;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    opacity: 1;
    z-index: 1;
    background: white;
    padding: 0 5px;
    -webkit-transform: translateY(60%);
            transform: translateY(60%); }
  .field .error {
    margin-top: 0.375rem;
    color: #dc0000;
    text-align: left;
    font-size: 1rem; }
    .field .error span {
      margin-left: 0.25rem; }

.submit {
  display: flex;
  justify-content: flex-end;
  margin-right: 0.5em; }
  .submit button {
    margin-left: 0.5rem; }

/*Colors*/
.red {
  color: #dc4b39 !important; }

.blue {
  color: #4785eb !important; }

.green {
  color: #3cb454 !important; }

.yellow {
  color: #fabc08 !important; }

.mark {
  background-color: #fcf8e3; }

.ml-1 {
  margin-left: .5rem; }

.ant-page-header {
  margin: 1rem .5rem 0; }

