.questions {
  background-color: white;
  padding: 1rem;
  border-radius: .5rem;
  margin: 5%;

  .feedback-steps {
    margin: 1rem;
  }

  .individual-plan {
    margin: 1rem 0;
    text-align: center;

    .submit {
      margin: 1rem 0;
      justify-content: center;
    }
  }

  .steps-action, .steps-content {
    margin: 1rem 0;
  }


  .error {
    text-align: center !important;
  }

  p {
    margin-top: 0;
    font-size: 1rem;
  }

  h3 {
    font-size: 1rem;
    text-indent: 1.5rem;
    text-align: justify;
    font-weight: normal;
  }

  .text-center {
    text-align: center;
  }

  .ant-radio-wrapper {
    font-size: 1.15rem;
    font-weight: normal;
  }

  .section {
    border: 2px solid #bfbfbf;
    margin: 1.3em 0;
    padding: 1rem;
    font-size: 1rem;

    
  }
  .section-title {
    font-weight: bolder;
  }

}
