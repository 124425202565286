.header {
  float: right;
  margin-right: 2rem;
margin-top: 1rem;
  &:hover {
    cursor: pointer;
  }

  img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }

  span {
    margin-left: 0.25rem;
    font-size: 1rem;
    color: black;
  }
}
@media (max-width: 800px) {
  .header {
    margin-bottom: 1rem;
  }
}
